$(document).ready(function () {
  $('.summernote').summernote({
    placeholder: 'Type something there!',
    fontNames: ['Lato'],
    tabsize: 2,
    height: 120,
    toolbar: [
      ['style', ['style']],
      ['fontname', ['fontname']],
      ['fontsize', ['fontsize']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph', 'height']],
      ['table', ['table']],
      ['insert', ['link', 'hr']],
      ['view', ['fullscreen', 'codeview', 'undo', 'redo', 'help']]
    ]
  });
});